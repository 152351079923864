.app-page {
  padding-top: 35px;
  padding-bottom: 75px;

  &__container {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
  }

  &__icon-wrapper {
    min-width: 94px;
    width: 94px;
    height: 94px;
    border-radius: 15px;
    overflow: hidden;

    picture {
      display: flex;
    }
  }

  &__title {
    color: $black;
    font-size: 24px;
    line-height: 120%;
    @include semi-bold;
    margin-bottom: 6px;
  }

  &__stats-count {
    display: inline-block;
    font-size: 14px;
    line-height: 150%;
    color: rgba(54, 70, 99, 0.8);;
    position: relative;
    padding-left: 22px;
    @include medium;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }

    &--downloads {
      margin-right: 11px;

      &::before {
        background-image: url('../../images/download-icon.svg');
      }
    }

    &--feedback {

      &::before {
        background-image: url('../../images/star-icon.svg');
      }
    }

    &--link {
      color: $blue;
      transition-duration: 300ms;
      text-decoration: none;
      @include semi-bold;

      &::before {
        background-image: url('../../images/link-icon.svg');
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 42px;
    gap: 8px;
  }

  &__download-links {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__download-link {
    display: flex;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    width: auto;
  }

  &__faq-link {
    width: 270px;
    text-decoration: none;
    font-size: 18px;
    @include medium;
    color: $blue;
    border: 2px solid $blue;
    border-radius: 8px;
    position: relative;
    padding: 8px 16px;
    text-align: center;
    height: 42px;

    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url('../../images/faq-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      top: 50%;
      left: 50%;
      transform: translateX(-50px) translateY(-50%);
    }
  }

  &__lead {
    font-size: 15px;
    line-height: 150%;
    @include semi-bold;
    color: rgba(54, 70, 99, 0.8);
    margin-bottom: 38px;
  }

  &__slider {
    margin-bottom: 38px;

    .splide__slide {
      border-radius: 10px;
      overflow: hidden;
    }

    .splide__arrow {
      opacity: 1;
      background-color: $white;
      width: 40px;
      height: 40px;
      box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

      &--prev {
        left: -20px;
      }

      &--next {
        right: -20px;
      }
    }
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    @include medium;
    color: rgba(54, 70, 99, 0.8);
  }
}

@media ($tablet) {

  .app-page {
    padding-top: 75px;

    &__container {
      padding: 0;
      width: 720px;
      margin: 0 auto;
    }

    &__header-wrapper {
      gap: 28px;
      align-items: flex-start;
      margin-bottom: 20px;

      &--empty {
        align-items: stretch;

        .app-page__title-wrapper {
          display: flex;
          align-items: center;
        }

        .app-page__title {
          margin: 0;
        }
      }
    }

    &__title {
      font-size: 45px;
    }

    &__stats-count {
      font-size: 18px;
      padding-left: 34px;

      &::before {
        width: 24px;
        height: 24px;
        top: 50%;
        margin-top: -12px;
      }

      &--downloads {
        padding-right: 20px;
        border-right: 2px solid #f1f6fc;
        margin-right: 15px;
      }
    }

    &__links {
      flex-direction: row;
      margin-bottom: 55px;
    }

    &__download-links {
      justify-content: flex-start;
      gap: 12px;
    }

    &__download-link {

      &--android {
        width: 192px;
        height: 57px;
      }

      &--ios {
        width: 171px;
        height: 57px;
      }
    }

    &__faq-link {
      width: auto;
      height: 57px;
      padding: 16px 12px 16px 48px;

      &::after {
        left: 12px;
        transform: translateX(0) translateY(-50%);
      }
    }

    &__lead {
      width: 655px;
      font-size: 18px;
      margin-bottom: 45px;
    }

    //
    //&__slider {
    //  .splide__slide {
    //    border-radius: 20px;
    //  }
    //}

    &__description {
      font-size: 18px;
      width: 685px;
    }
  }
}

@media ($desktop) {

  .app-page {
    padding-bottom: 75px;
  }
}

@media ($desktop-hd) {

  .app-page {
    width: 912px;

    &__container {
      margin: 0;
      width: auto;
    }
  }
}